import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Spinner component is used to indicate users that their action is being processed. You can customize the size and color of the spinner with the provided properties.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/spinner-1.svg",
      "alt": "spinner guidelie"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Track :`}</strong>{` a track that will be filled by the indicator either determinate or indeterminate way.`}</li>
      <li parentName="ol"><strong parentName="li">{`Indicator :`}</strong>{` is a circular element that will fill the invisible circular track with color, as the indicator spin 360 degrees.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Spinners are used to demonstrate data loading. Spinners are offered in different sizes that follow the sizing of our icons. These sizes include extra small, small, medium and large. Depending on the size of information you are loading and the space you are dealing with, we recommend using the spinner size that generally matches.`}</p>
    <p><strong parentName="p">{`Common places spinner appear:`}</strong>{` Loading screen and else where when needed`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> Users know progress on their action in the UI is being made.</li>
      <li> To keep users from giving up on an experience.</li>
      <li> Especially after user input to prevent repeated action.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> Progress on a large task with steps users should complete.</li>
      <li> For progress on a process out of their control.</li>
      <li> Don’t use to show progress. Favor a progress bar instead.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`When a change occurs in the UI, like a loading state, it’s good to notify a user what is happening. While a spinner visually indicates that a process is happening`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : Visible indicator" caption="Visible indicating users that their action is being processed" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/spinner-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Not visible indicator" caption="The Indicator not visible for users know action is being processed" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/spinner-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating spinner :`}</p>
    <ul>
      <li parentName="ul">{`Only show a spinner if the expected wait time is more than a second.`}</li>
      <li parentName="ul">{`If only a portion of a page is displaying new content or being updated, place the spinner in that part of the page.`}</li>
      <li parentName="ul">{`If you are unsure where to place the spinner, place it where you want the user’s attention to be when loading is finished.`}</li>
      <li parentName="ul">{`You can add supporting text to let the user know what is happening.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With supporting text" caption="Use the label property to provide an accessible label for the expectation information the action is being processed" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/spinner-4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : With progress information" caption="Use the label property to provide currently step or state is being processed to user, and the user can control the spinner" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/spinner-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      